'use client';
import { Button } from '@prickle/next-ui';
import { useTranslations } from 'next-intl';
import { useFormStatus } from 'react-dom';

export const LoginButton = () => {
  const t = useTranslations('LoginPage.actions');
  const { pending } = useFormStatus();

  return (
    <Button type="submit" disabled={pending} color="primary" size="block">
      {t('login')}
    </Button>
  );
};
