'use client';
import { useFormState } from 'react-dom';
import { authenticateWithCredentials } from '../actions/authenticate-with-credentials.action';
import { LoginButton } from './login-button.component';
import { TextInput } from '@prickle/next-ui';
import { ErrorMessage } from '@tuning-bytes/users/ui';
import { useTranslations } from 'next-intl';

export const CredentialsForm = () => {
  const t = useTranslations('LoginPage.form');
  const [errorMessage, dispatch] = useFormState(
    authenticateWithCredentials,
    undefined,
  );

  return (
    <form action={dispatch} className="space-y-4">
      <div className="space-y-2">
        <TextInput
          name="email"
          placeholder="example@acme.com"
          bordered
          label={{
            text: t('email'),
            position: 'top',
            required: true,
          }}
        />
      </div>
      <div className="space-y-2">
        <TextInput
          name="password"
          type="password"
          bordered
          label={{
            text: t('password'),
            position: 'top',
            required: true,
          }}
        />
      </div>
      <LoginButton />

      <ErrorMessage errorMessage={errorMessage} />
    </form>
  );
};
